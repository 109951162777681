<template>
  <v-card class="controls">
    <v-row class="controls-row">
      <v-col class="no-padding" cols="3">
        <v-row class="no-padding align-center">
          <v-col cols="1">
            <v-icon dark> mdi-video</v-icon>
          </v-col>
          <v-col cols="10">
            <v-select
              v-model="selectedVideoInput"
              :hide-details="true"
              :items="videoInputDevices"
              dark
              dense
              item-text="label"
              item-value="deviceId"
              solo
              @focus="updateDevices"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="no-padding" cols="3">
        <v-row class="no-padding align-center">
          <v-col cols="1">
            <v-icon dark>fa-microphone</v-icon>
          </v-col>
          <v-col cols="10">
            <v-select
              v-model="selectedAudioInput"
              :hide-details="true"
              :items="audioInputDevices"
              dark
              dense
              item-text="label"
              item-value="deviceId"
              solo
              @focus="updateDevices"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="no-padding" cols="3">
        <v-row class="no-padding align-center">
          <v-col cols="1">
            <v-icon dark>fa-volume-up</v-icon>
          </v-col>
          <v-col cols="10">
            <v-select
              v-model="selectedAudioOutput"
              :hide-details="true"
              :items="audioOutputDevices"
              dark
              dense
              item-text="label"
              item-value="deviceId"
              solo
              @focus="updateDevices"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { useVideoRoomStore } from "@/pinia-store/videoRoom";

export default {
  data() {
    return {
      audioOutputDevices: [],
      audioInputDevices: [],
      videoInputDevices: [],
    };
  },
  computed: {
    ...mapState(useVideoRoomStore, ["settings"]),
    selectedVideoInput: {
      get() {
        return this.settings && this.settings.videoInput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: value,
          audioInput: this.settings && this.settings.audioInput,
          audioOutput: this.settings && this.settings.audioOutput,
        });
        this.$emit("onChangeVideoInput");
      },
    },
    selectedAudioInput: {
      get() {
        return this.settings && this.settings.audioInput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: this.settings && this.settings.videoInput,
          audioInput: value,
          audioOutput: this.settings && this.settings.audioOutput,
        });
        this.$emit("onChangeAudioInput");
      },
    },
    selectedAudioOutput: {
      get() {
        return this.settings && this.settings.audioOutput;
      },
      set(value) {
        this.setRoomSettings({
          videoInput: this.settings && this.settings.videoInput,
          audioInput: this.settings && this.settings.audioInput,
          audioOutput: value,
        });
        this.$emit("onChangeAudioOutput");
      },
    },
  },
  methods: {
    ...mapActions(useVideoRoomStore, ["setDefaultRoomSettings"]),
    ...mapActions(useVideoRoomStore, ["setRoomSettings"]),
    async updateDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoInputDevices = devices.filter((device) => device.kind === "videoinput");
      this.audioInputDevices = devices.filter((device) => device.kind === "audioinput");
      this.audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
    },
  },
  async mounted() {
    await this.updateDevices();
    await this.setDefaultRoomSettings();
  },
};
</script>
<style lang="scss" scoped>
.controls {
  max-width: fit-content;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px 0;
  background: #1e1e1e;
  border: 1px solid rgba(46, 46, 46, 0.56);
  box-sizing: border-box;
  z-index: 2;

  &-row {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
  }
}

.no-padding {
  padding: 0;
}
</style>
